<template>
  <b-sidebar
    id="opened-add-withdraw"
    :visible="openedAddWithdraw"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-add-withdraw', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('account.titles.finance.withdraw') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card class="card-transaction">
        <!-- BODY -->

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="amount"
                type="number"
                :placeholder="$t('account.text.finance.add_withdraw_amount')"
              />
              <b-form-text v-if="withdrawWithCommission">
                {{ $t('account.titles.finance.you_get') }} {{ $options.filters.moneyFormat(withdrawWithCommission) }} {{ $t('account.titles.finance.with_commission') }}
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-tabs
              v-model="method"
              pills
            >
              <b-tab :title="`UAH`">
                <b-form-group
                  label-for="maskdescription"
                >
                  <InputMask
                    id="maskdescription"
                    v-model="maskdescription"
                    mask="9999-9999-9999-9999"
                    class="form-control"
                    :placeholder="$t('account.text.finance.description')"
                  />
                  <b-form-text>
                    {{ $t('account.titles.finance.duration') }}
                  </b-form-text>
                </b-form-group>
              </b-tab>
              <b-tab :title="`USDT TRC-20`">
                <b-form-group
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="description"
                    class="form-control"
                    :placeholder="$t('account.text.finance.description')"
                  />
                </b-form-group>
              </b-tab>
            </b-tabs>
          </b-col>
          <b-col
            v-if="withdrawCommission"
            cols="12"
          >
            <b-alert
              variant="warning"
              :show="true"
            >
              <div class="alert-body d-flex">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50 mt-25"
                />
                {{ withdrawCommission }}
              </div>
            </b-alert>
          </b-col>
          <b-col
            v-if="withdrawHasAlert"
            cols="12"
          >
            <b-alert
              variant="danger"
              :show="true"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                {{ withdrawHasAlert }}
              </div>
            </b-alert>
          </b-col>
          <b-col cols="12">
            <b-alert
              :show="true"
              variant="secondary"
            >
              <div
                class="alert-body text-center"
                v-html="$t('account.text.finance.add_withdraw_confirmation')"
              />
            </b-alert>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              block
              :disabled="!!withdrawHasAlert || !amount || (!description && !maskdescription)"
              @click="submit"
            >
              {{ $t('account.btn.finance.withdraw_short') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BCard, BRow, BCol, BFormGroup, BFormInput, BFormText, BAlert, BButton, BTabs, BTab,
} from 'bootstrap-vue'
import InputMask from 'vue-input-mask'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BTab,
    BTabs,
    BSidebar,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormText,
    BAlert,
    BButton,
    InputMask,
  },
  model: {
    prop: 'openedAddWithdraw',
    event: 'update:opened-add-withdraw',
  },
  props: {
    openedAddWithdraw: {
      type: Boolean,
      required: true,
    },
    amount: {
      type: [Number, String],
      required: true,
    },
    balance: {
      type: [Number, String],
      required: true,
    },
    settings: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      maskdescription: null,
      description: null,
      method: 0,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
    withdrawCommission() {
      return this.settings.cw && this.settings.cwm ? this.$i18n.t('account.text.finance.add_withdraw_commission').replace(':comm', this.settings.cw).replace(':min_comm', this.$options.filters.moneyFormat(this.settings.cwm)) : null
    },
    withdrawWithCommission() {
      let commission = (this.amount * this.settings.cw) / 100
      if (commission < parseInt(this.settings.cwm, 10)) {
        commission = parseInt(this.settings.cwm, 10)
      }
      return this.amount ? parseInt(this.amount, 10) - commission : null
    },
    withdrawHasAlert() {
      let alert = null
      if (parseInt(this.amount, 10) < parseInt(this.settings.awmn, 10)) {
        alert = `${this.$i18n.t('account.transaction.alert.amount_withdraw_min')} ${this.$options.filters.moneyFormat(this.settings.awmn)}`
      }
      if (parseInt(this.amount, 10) > parseInt(this.settings.awmx, 10)) {
        alert = `${this.$i18n.t('account.transaction.alert.amount_withdraw_max')} ${this.$options.filters.moneyFormat(this.settings.awmx)}`
      }
      if (parseInt(this.amount, 10) > parseInt(this.balance, 10)) {
        alert = `${this.$i18n.t('account.transaction.alert.amount_withdraw_balance')}`
      }
      return alert
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-add-withdraw', false)
    },
    submit() {
      this.$http.post('/api/user/transactions/', {
        amount: this.amount,
        description: this.method ? this.description : this.maskdescription,
        type: '-',
        method: this.method ? 'cryptomus' : 'wayforpay',
      })
        .then(() => {
          this.$store.dispatch('validation/clearErrors')
          this.$emit('refetch-data')
          this.$emit('update:opened-add-withdraw', false)

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-add-withdraw {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
