<template>
  <b-sidebar
    id="opened-add-order"
    :visible="openedAddOrder"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-add-order', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('account.titles.finance.add_order') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card class="card-transaction">
        <!-- BODY -->

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="amount"
                type="number"
                :placeholder="$t('account.text.finance.add_order_amount')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="description"
                :placeholder="$t('account.text.finance.add_order_description')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-alert
              :show="true"
              variant="secondary"
            >
              <div
                class="alert-body text-center"
                v-html="$t('account.text.finance.add_found_confirmation')"
              />
            </b-alert>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              class="pl-1 pr-1"
              block
              :disabled="!description || !amount"
              @click="submit('wayforpay')"
            >
              <feather-icon
                icon="CreditCardIcon"
                class="mr-50"
              />
              Visa/MasterCard
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-button
              variant="primary"
              class="pl-1 pr-1"
              block
              :disabled="!description || !amount"
              @click="submit('cryptomus')"
            >
              <feather-icon
                icon="DollarSignIcon"
                class="mr-50"
              />
              Tether USDT
            </b-button>
          </b-col>
        </b-row>

      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BCard, BRow, BCol, BFormGroup, BFormInput, BAlert, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BSidebar,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BAlert,
    BButton,
  },
  model: {
    prop: 'openedAddOrder',
    event: 'update:opened-add-order',
  },
  props: {
    openedAddOrder: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      amount: null,
      description: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-add-order', false)
    },
    submit(method = 'wayforpay') {
      this.$http.post('/api/user/transactions/order/', {
        amount: this.amount,
        description: this.description,
        method,
      })
        .then(response => {
          this.$store.dispatch('validation/clearErrors')
          this.$emit('refetch-data')
          this.$emit('update:opened-add-order', false)
          if (!response.data.data || !response.data.data.invoice || response.data.data.invoice.state === 'error') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: response.data.data.invoice.state,
                text: response.data.data.invoice.error,
                icon: 'AlertIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$emit('init-payment', { method, invoice: response.data.data.invoice })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              text: 'Перегляньте та заповніть поля',
              icon: 'AlertIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-add-order {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
